import _ from 'lodash';
import * as log from '@/gr/common/log';
import fileSaver from 'file-saver';
import { TrendDataDefinition, TrendDataRepository, TrendData, Messages, FileNamingService, DateTimeFormatter } from '@/apps/timeSeriesViewer';
import * as json2csv from 'json2csv';
import ko from 'knockout';

export class CsvDownloadService {
  constructor(
    private readonly _trendDataRepository: TrendDataRepository,
    private readonly _fileNamingService: FileNamingService
  ) {}

  async downloadAsCsv(dataDefinition: TrendDataDefinition, messages: Messages.Args, progress: KnockoutObservable<string>, isLoading: KnockoutObservable<boolean>): Promise<void> {
    try {
      const trendData = ko.observable<TrendData | null>(null);
      await this._trendDataRepository.fillTrendData(dataDefinition, 'FILE', trendData, progress, isLoading);

      const data = trendData();
      if (data) {
        data?.messages.forEach((message) => messages.add(message.html, message.type));
        this.saveAsCsv(data);
      } else messages.add('No data to save');
    } catch (error) {
      messages.add('Failed to load data for csv file');
      log.errorEx(error, 'Failed to load data for csv file');
    }
  }

  private saveAsCsv(trendData: TrendData) {
    const filename = `${this._fileNamingService.getDefaultFileName(trendData)}.csv`;

    const csv = this.convertToCsv(trendData);

    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
    fileSaver.saveAs(blob, filename);
  }

  private convertToCsv(trendData: TrendData) {
    // Format time stamps
    const data = trendData.dataPoints.map((dataPoints) => {
      const newDataPoints = _.clone(dataPoints);
      newDataPoints.timeStamp = DateTimeFormatter.dateTimeForExcel(newDataPoints.timeStamp, trendData.utcOffset);
      return newDataPoints;
    });

    const fields = [{ label: `Time (${trendData.utcOffset.displayName})`, value: 'timeStamp' }].concat(
      trendData.configuredDataDefinitions.map((c) => {
        const formattedUnits = c.unitsOfMeasure && c.unitsOfMeasure.displayName.length > 0 ? ` (${c.unitsOfMeasure.displayName})` : '';
        return { label: c.displayNameOrDefault() + formattedUnits, value: c.id };
      })
    );

    return json2csv.parse(data, { fields: fields });
  }
}
