import ko from 'knockout';
import { TrendData, TrendDataDefinition, OutputChannelTypeId } from '@/apps/timeSeriesViewer';
import { TrendsMetricsApi } from '@/apps/timeSeriesViewer/repos/api/trendsMetricsApi';

export class TrendDataRepository {
  constructor(private _trendsMetrics: TrendsMetricsApi) {}

  async fillTrendData(
    dataDefinition: TrendDataDefinition,
    purpose: OutputChannelTypeId,
    trendData: KnockoutObservable<TrendData | null>,
    progress: KnockoutObservable<string> = ko.observable(''),
    isLoading: KnockoutObservable<boolean> = ko.observable(false)
  ): Promise<void> {
    await this._trendsMetrics.fillData(dataDefinition, purpose, trendData, progress, isLoading);
  }
}
